//
//// Custom Theming for Angular Material
//// For more information: https://material.angular.io/guide/theming
//@use '@angular/material' as mat;
//// Plus imports for other components in your app.
//
//$centro-terapeutico-cubic-typography: mat.define-typography-config(
//  // Specify "Comic Sans" as the default font family for all levels.
//  $font-family: 'Metropolis',
//);
//// Include the common styles for Angular Material. We include this here so that you only
//// have to load a single css file for Angular Material in your app.
//// Be sure that you only ever include this mixin once!
//@include mat.core($centro-terapeutico-cubic-typography);
//
//// Define the palettes for your theme using the Material Design palettes available in palette.scss
//// (imported above). For each palette, you can optionally specify a default, lighter, and darker
//// hue. Available color palettes: https://material.io/design/color/
//$centro-terapeutico-cubic-primary: mat.define-palette(mat.$indigo-palette);
//$centro-terapeutico-cubic-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
//
//// The warn palette is optional (defaults to red).
//$centro-terapeutico-cubic-warn: mat.define-palette(mat.$red-palette);
//
//
//// Create the theme object. A theme consists of configurations for individual
//// theming systems such as "color" or "typography".
//$centro-terapeutico-cubic-theme: mat.define-light-theme((
//  color: (
//    primary: $centro-terapeutico-cubic-primary,
//    accent: $centro-terapeutico-cubic-accent,
//    warn: $centro-terapeutico-cubic-warn,
//  ),
//  typography: $centro-terapeutico-cubic-typography
//));
//
//// Include theme styles for core and each component used in your app.
//// Alternatively, you can import and @include the theme mixins for each component
//// that you are using.
//@include mat.all-component-themes($centro-terapeutico-cubic-theme);
//
///* You can add global styles to this file, and also import other style files */
//

/**
 * ITCSS:
 * Metodología para escribir código que es escalable y fácil de manipular.
 **/

/// @group SETTINGS
// Esto contiene cualquier configuración global para su proyecto.
@import "assets/scss/01.settings/variables";

/// @group TOOLS
// Herramientas disponibles a nivel mundial, es decir, mezclas y funciones (mixins).

/// @group GENERIC
// Contiene cosas como Normalize.css, reglas globales de tamaño de caja, restablecimientos CSS
@import "assets/scss/03.generic/theming";
//@import "assets/scss/03.generic/page";

// ELEMENTS
// Contiene css sin clase para elementos HTML
@import "assets/scss/04.elements/globals";
//@import "assets/scss/04.elements/form";
//@import "assets/scss/04.elements/material/form-field";

// OBJECTS
// Esta es la primera capa en la que encontramos selectores basados ​​en la clase.
// Estos se ocupan de diseñar patrones de diseño no cosméticos, o "objetos" (OOCSS).

// COMPONENTS
// La capa de componentes es donde comenzamos a diseñar piezas reconocibles de interfaz de usuario.
// No deberíamos encontrar selectores con una especificidad inferior a una clase en esta capa.
//@import "assets/scss/06.components/icon";

// TRUMPS
// Esta capa supera a todas las demás capas y tiene el poder de anular cualquier cosa que haya pasado antes.
// Es inelegante y pesado, y contiene clases de utilidad y ayuda, hacks y anulaciones.
//@import "assets/scss/07.trumps/trumps";

@tailwind base;
@tailwind components;
@tailwind utilities;


@import "assets/colors/color";
@import "assets/fonts/fonts";

html, body {
  height: 100%;
}

body {
  margin: 0;
}
