@use '@angular/material' as mat;


$mat-typography: mat.define-typography-config(
  // Specify "Comic Sans" as the default font family for all levels.
  $font-family: 'Metropolis',
);
// Compute font config
@include mat.core($mat-typography);

$theme-primary: mat.define-palette($mat-primary, main, lighter, darker);

body {
  --accent-color: #f18e24;
  --accent-lighter-color: #fbddbd;
  --accent-darker-color: #eb7116;
  --text-accent-color: #{$dark-primary-text};
  --text-accent-lighter-color: #{$dark-primary-text};
  --text-accent-darker-color: #{$dark-primary-text};
}
$mat-accent: (
  main: #f18e24,
  lighter: #fbddbd,
  darker: #eb7116,
  200: #f18e24, // For slide toggle,
  contrast : (
    main: $light-primary-text,
    lighter: $light-primary-text,
    darker: $light-primary-text,
  )
);
$theme-accent: mat.define-palette($mat-accent, main, lighter, darker);


body {
  --warn-color: #e63a3a;
  --warn-lighter-color: #f8c4c4;
  --warn-darker-color: #db2525;
  --text-warn-color: #{$light-primary-text};
  --text-warn-lighter-color: #{$dark-primary-text};
  --text-warn-darker-color: #{$light-primary-text};
}
$mat-warn: (
  main: #e63a3a,
  lighter: #f8c4c4,
  darker: #db2525,
  200: #e63a3a, // For slide toggle,
  contrast : (
    main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  )
);
$theme-warn: mat.define-palette($mat-warn, main, lighter, darker);

$theme: mat.define-light-theme($theme-primary, $theme-accent, $theme-warn);

// Theme Init
@include mat.all-component-themes($theme);
