@font-face {
  font-family: 'icomoon';
  src: url('icomoon.eot?eocgy0');
  src: url('icomoon.eot?eocgy0#iefix') format('embedded-opentype'),
  url('icomoon.ttf?eocgy0') format('truetype'),
  url('icomoon.woff?eocgy0') format('woff'),
  url('icomoon.svg?eocgy0#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-icon_DIANA:before {
  content: "\e904";
}

.icon-icon_OJO .path1:before {
  content: "\e905";
  color: rgb(255, 255, 255);
}

.icon-icon_OJO .path2:before {
  content: "\e906";
  margin-left: -1.111328125em;
  color: rgb(60, 60, 59);
}

.icon-icon_OJO .path3:before {
  content: "\e907";
  margin-left: -1.111328125em;
  color: rgb(60, 60, 59);
}

.icon-icon_OJO .path4:before {
  content: "\e908";
  margin-left: -1.111328125em;
  color: rgb(60, 60, 59);
}

.icon-icon_OJO .path5:before {
  content: "\e909";
  margin-left: -1.111328125em;
  color: rgb(60, 60, 59);
}

.icon-icon_OJO .path6:before {
  content: "\e90a";
  margin-left: -1.111328125em;
  color: rgb(60, 60, 59);
}

.icon-icon_OJO .path7:before {
  content: "\e90b";
  margin-left: -1.111328125em;
  color: rgb(60, 60, 59);
}

.icon-icon_OJO .path8:before {
  content: "\e910";
  margin-left: -1.111328125em;
  color: rgb(60, 60, 59);
}

.icon-icon_OJO .path9:before {
  content: "\e911";
  margin-left: -1.111328125em;
  color: rgb(60, 60, 59);
}

.icon-icon_OJO .path10:before {
  content: "\e912";
  margin-left: -1.111328125em;
  color: rgb(60, 60, 59);
}

.icon-icon_OJO .path11:before {
  content: "\e913";
  margin-left: -1.111328125em;
  color: rgb(60, 60, 59);
}

.icon-icon_OJO .path12:before {
  content: "\e914";
  margin-left: -1.111328125em;
  color: rgb(60, 60, 59);
}

.icon-icon_OJO .path13:before {
  content: "\e915";
  margin-left: -1.111328125em;
  color: rgb(60, 60, 59);
}

.icon-icon_RELOJ:before {
  content: "\e916";
}

.icon-Circulo:before {
  content: "\e900";
}

.icon-Comillas-Up:before {
  content: "\e901";
}

.icon-Comillas-Down:before {
  content: "\e902";
}

.icon-Cubos:before {
  content: "\e903";
}

.icon-Arrow-Left .path1:before {
  content: "\e90c";
  color: rgb(255, 255, 255);
}

.icon-Arrow-Left .path2:before {
  content: "\e90d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-Arrow-Right .path1:before {
  content: "\e90e";
  color: rgb(0, 0, 0);
}

.icon-Arrow-Right .path2:before {
  content: "\e90f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-Menu:before {
  content: "\e92e";
}

.icon-Phone .path1:before {
  content: "\e92f";
  color: rgb(0, 0, 0);
}

.icon-Phone .path2:before {
  content: "\e930";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-Mail .path1:before {
  content: "\e931";
  color: rgb(255, 255, 255);
}

.icon-Mail .path2:before {
  content: "\e932";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-Marker .path1:before {
  content: "\e933";
  color: rgb(255, 255, 255);
}

.icon-Marker .path2:before {
  content: "\e934";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-Instagram .path1:before {
  content: "\e935";
  color: rgb(255, 255, 255);
}

.icon-Instagram .path2:before {
  content: "\e936";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-Instagram .path3:before {
  content: "\e937";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-Instagram .path4:before {
  content: "\e938";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-Facebook .path1:before {
  content: "\e939";
  color: rgb(255, 255, 255);
}

.icon-Facebook .path2:before {
  content: "\e93a";
  margin-left: -0.990234375em;
  color: rgb(255, 255, 255);
}

.icon-Logo:before {
  content: "\e93b";
}

.icon-MiddleCircle:before {
  content: "\e93c";
}

.icon-LogopediaBlack:before {
  content: "\e93d";
}

.icon-Servicios_Negro-07:before {
  content: "\e93e";
}

.icon-Servicios_Negro-08 .path1:before {
  content: "\e93f";
  color: rgb(0, 0, 0);
}

.icon-Servicios_Negro-08 .path2:before {
  content: "\e940";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-Servicios_Negro-08 .path3:before {
  content: "\e941";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-Servicios_Negro-08 .path4:before {
  content: "\e942";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-Servicios_Negro-08 .path5:before {
  content: "\e943";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-Servicios_Negro-08 .path6:before {
  content: "\e944";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-Servicios_Negro-08 .path7:before {
  content: "\e945";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-Servicios_Negro-08 .path8:before {
  content: "\e946";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-Servicios_Negro-08 .path9:before {
  content: "\e947";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-Servicios_Negro-08 .path10:before {
  content: "\e948";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-Servicios_Negro-08 .path11:before {
  content: "\e949";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-Servicios_Negro-08 .path12:before {
  content: "\e94a";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-Servicios_Negro-09:before {
  content: "\e94b";
}


