////
/// Css de etiquetas globales
/// Contiene cosas como Normalize.css, reglas globales de tamaño de caja, restablecimientos CSS
/// @group GENERIC
////

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Metropolis Regular", "Helvetica Neue", sans-serif;
}

p {
  color: $dark-text;
}
