@font-face {
  font-family: Metropolis;
  font-display: swap;
  font-style: normal;
  font-weight: normal;
  src: url(Metropolis-Regular.otf);
}


@font-face {
  font-family: Metropolis;
  font-display: swap;
  font-style: normal;
  font-weight: 600;
  src: url(Metropolis-Medium.otf);
}


@font-face {
  font-family: Metropolis;
  font-display: swap;
  font-style: normal;
  font-weight: 300;
  src: url(Metropolis-Light.otf);
}

@font-face {
  font-family: Metropolis;
  font-display: swap;
  font-weight: bold;
  src: url(Metropolis-Bold.otf);
}
