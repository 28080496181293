@font-face {
  font-family: GeosansLight;
  font-style: normal;
  font-display: swap;
  src: url(GeosansLight.ttf);
}


@font-face {
  font-family: GeosansLight;
  font-style: oblique;
  font-display: swap;
  src: url(GeosansLight-Oblique.ttf);
}
